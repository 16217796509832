
<template>
    <div class="accep bgblue">
        <div class="user">
            <div class="form" style="margin-top: 20px;margin-left: 20px;padding: 10px;">
               <el-form
                :model="formInline"
                class="demo-form-inline"
                style="position: relative;"
                label-width="100px"
              >
                <el-row>
          <!-- <el-col :span="4"
            ><el-form-item label="施工单号">
              <el-input
                v-model="formInline.constructionCode"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col> -->
          <el-col :span="4"
            ><el-form-item label="客户名称">
              <el-input
                v-model="formInline.customerName"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>


          <el-col :span="4"
            ><el-form-item label="手机">
              <el-input
                v-model="formInline.customerPhone"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="证件号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="电站类型">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="验收类型">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="超时状态">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>

          <el-col :span="4"
            ><el-form-item label="验收复查">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="安装单据类型">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="4" style="text-align: right;">
                      <el-button type="primary" @click="query">
                        <i class="el-icon-search"></i>查询</el-button
                      >
                      <el-button plain @click="reset">
                        <i class="el-icon-refresh"></i>重置</el-button
                      >
                    </el-col>
                </el-row>

               </el-form>
            </div>
        </div>
        <div class="biao">
            <el-row>
        <el-button type="primary" plain>
          <i class="el-icon-upload2"></i>&nbsp;&nbsp;导出</el-button
        >

            </el-row>
            <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 380px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column
          prop=" "
          label="操作"
          width="70"
          align="center"

        >
        <template slot-scope="scope">
            <el-button type="text" class="btn-orange" @click="sh(scope.row.customerCode)"
              >审核</el-button
            >
          </template>


        </el-table-column>
        <!-- <el-table-column
          prop="constructionCode"
          label="施工单号"
          width="250"
          align="center"
        > -->
        </el-table-column>
        <el-table-column
          prop="customerName"
          label="客户名称"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="customerPhone"
          label="手机号"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="施工提交时间"
          width="170"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="constructionAddress"
          label="地址"
          width="300"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="examineStatus"
          label="审核状态"
          width="150"
          align="center"
        >
        <template slot-scope="scope">
            <el-button
              type="text"
              class="btn-blue"
              >
                未审核
                 </el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="电站类型"
          width="250"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="headName"
          label="施工人员"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="施工日期"
          width="250"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="dispatchDate"
          label="派单时间"
          width="150"
          align="center"
        >
        </el-table-column>


      </el-table>
      <div class="pagination">
          <el-pagination
             @size-change="handleSizeChange"
             @current-change="handleCurrentChange"
             :current-page.sync="queryInfo.currPage"
             :page-size="queryInfo.pageSize"
             layout="total, sizes, prev, pager, next, jumper"
             :total="count"
           >
          </el-pagination>
      </div>
        </div>
        <el-drawer
        title="客户详情"
        :visible.sync="drawer"
        size="70%">
      <div class="drw">
        <div class="drw-left">
           <div class="drw-user">
               <div class="jj">
                 <div>客户编号：{{ userdetail.customerNumber  }}</div>
                 <div>姓名：  {{ userdetail.customerName}}</div>
               </div>
               <div  class="jj">
                 <div>创建日期：{{ userdetail.createDate}}</div>
                 <div>地址： {{ userdetail.customerNumber}}</div>
               </div>
           </div>
           <div class="anxx">
              <div>安装信息</div>
           </div>
           <div class="box">
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>支架照片</div>
            </div>
            <div class="zjli">
                <div class="zj-img">
                    <div>整体主视图 左视图照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.holderMainImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>支架基座、水泥墩基座照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.holderBaseImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>拉杆及斜背拉照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.holderBarImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>东、西边压块照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.holderEastwestImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>基础防水照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.holderWaterproofImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>组件照片</div>
            </div>
            <div class="zjli">
                <div class="zj-img">
                    <div>整体照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.moduleMainImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>东南西三个方向照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.moduleThreedirectionImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>组件间、组件与支架接地照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.moduleHolderBaseImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>组件阵列倾角照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.moduleDipAngleImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>组件近景照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.moduleCloseShotImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>压块、背压板照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.moduleBlockImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>组件铭牌照</div>
                    <div v-for="(item, index) in userdata.moduleNameplateImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                    </div>
                </div>
            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>逆变器照片</div>
            </div>
            <div class="zjli">
                <div class="zj-img">
                    <div>逆变器整体照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.inverterMainImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>直流侧输入端子接线照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.inverterMainImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>

            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>配电箱照片</div>
            </div>
            <div class="zjli">
                <div class="zj-img">
                    <div>配电箱整体照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.boxMainImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>配电箱内元器件及电缆进出孔封堵照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.boxInnerImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>

            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>电缆敷设照片</div>
            </div>
            <div class="zjli">
                <div class="zj-img">
                    <div>电缆敷设整体及穿管固定照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.cableMainImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>接地照片</div>
            </div>
            <div class="zjli">
                <div class="zj-img">
                    <div>接地整体照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.groundingMainImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
                <div class="zj-img">
                    <div>支架接地、配电箱电气接地电阻测量照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.groundingHolderImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>

            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>接地照片</div>
            </div>
            <div class="zjli">
                <div class="zj-img">
                    <div>逆变器、采集器铭牌照片</div>
                    <div class="img-li">
                      <div v-for="(item, index) in userdata.nameplateImgList">
                        <el-image
                          style="width: 100px; height: 100px;margin: 10px;"
                          :src="item.constructionImgUrl"
                          :preview-src-list="[item.constructionImgUrl]"
                        >
                        </el-image>
                      </div>
                    </div>
                </div>
            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>光伏组件SN号</div>
            </div>
            <div style="display: flex;flex-wrap: wrap;margin-left: 10px;">
              <div style="color:  #555555;font-size: 16px;margin: 20px;" v-for="(ite,i) in userdata.pvList">{{ ite.componentNo }}</div>
            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>逆变器SN号</div>
            </div>
            <div style="display: flex;flex-wrap: wrap;margin-left: 10px;">
              <div style="color:  #555555;font-size: 16px;margin: 20px;" v-for="(ite,i) in userdata.inverterList">{{ ite.componentNo }}</div>
            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>采集器SN号</div>
            </div>
            <div style="display: flex;flex-wrap: wrap;margin-left: 10px;">
              <div style="color:  #555555;font-size: 16px;margin: 20px;" v-for="(ite,i) in userdata.collectorList">{{ ite.componentNo }}</div>
            </div>
            <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>并网箱SN号</div>
            </div>
            <div style="display: flex;flex-wrap: wrap;margin-left: 10px;">
              <div style="color:  #555555;font-size: 16px;margin: 20px;" v-for="(ite,i) in userdata.parallelcageList">{{ ite.componentNo }}</div>
            </div>
            <div class="de-bom">
                  <div class="bom-1" style="align-items: center;">
                    <div style="margin-right: 10px;">审核结果:</div>
                    <div>
                      <el-radio-group v-model="ondelist.result">
                        <el-radio :label="1" border>通过</el-radio>
                        <el-radio :label="0" border>不通过</el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                  <div class="bom-1" style="align-items: center;">
                    <div style="margin-right: 10px;">审核意见:</div>
                    <el-button style="height: 32px;" type="primary" @click="pushone()"><i class="el-icon-plus"></i>添加审核意见</el-button>
                  </div>
                  <div class="tips" v-for="(item,i) in ondelist.commentList" :key="i">
                    <div class="tips-tit">审核意见：</div>
                    <div class="xz" style="display: flex;">
                      <div class="xz_tit">选择照片：</div>
                      <el-button style="height: 32px;" type="primary" @click="openimg(i,0)"
                        ><i class="el-icon-plus"></i>选择照片</el-button
                      >
                    </div>
                    <div class="imglis" style="margin-left: 30px;display: flex">
                      <div style="width: 100px;height: 100px;margin-right: 20px;" class="imgliscoo" v-show="x.commentImgUrl"  v-for="(x,d) in ondelist.commentList[i].commentImgList">
                        <img

                        style="width: 100px;height: 100px;margin-right: 20px;"
                        :src="x.commentImgUrl"

                        alt=""
                        />
                        <div class="colseimg" @click="colseimg(i,d)">
                          <i class="el-icon-delete"></i>
                        </div>
                      </div>
                    </div>
                    <div class="yj" style="display: flex;">
                      <div class="xz_tit">审核意见：</div>
                      <textarea
                        v-model="item.comment"
                        style="width:70%;height: 100px;"
                        name=""
                        id=""
                        placeholder="请输入审核意见"
                      ></textarea>
                    </div>
                    <div class="colse" @click="close(i)">
                    <i class="el-icon-delete"></i>
                    </div>
                  </div>
            </div>
            <div class="box-btn">
            <el-button
              class="bo"
              style="width: 90px;height: 30px;"
              type="primary"
              @click="dialogVisible=true"
              >审核完成</el-button
            >
          </div>
           </div>
        </div>
        <div class="drw-right">
          <!-- <el-button class="bo" type="primary" @click="dialogVisible=true">审核完成</el-button> -->
          <div class="right-tit" style="color: #333333;font-size: 20px;">历史记录</div>
          <div class="timex" style="margin-top: 40px;height: 62vh;overflow: auto;">
              <el-steps class="teps" reverse="false"  finish-status="success" align-center direction="vertical" :rtl="true">
                <el-step  v-for="(act, index) in rightdata"
                  :key="index"
                  >
                  <template slot="description" >
                    <div class="shjl" >
                    <div style="color: #d6aa7f;font-size: 12px;margin-bottom: 8px;">{{ act.createDate }}</div>
                    <div>审核人:  <span style="color: #409eff;">{{ act.createName}}</span></div>
                    <div>
                   审核结果:
                    <span  :class="act.result==1?'spnacitiveon':'spnacitive'">【{{ act.result==1?"通过":"不通过" }}】</span>
                    </div>
                    <div v-for="(e,h) in act.commentList">
                      <div v-if="e. comment">审核意见： {{ e. comment}}</div>
                      <img  style="width: 50px;height: 50px;" v-for="(d,f) in e.commentImgList" :src="d.commentImgUrl" v-if="d.commentImgUrl" alt="">
                  </div>
                  </div>

                  </template>

                </el-step>
                <el-step>
                  <template slot="description" >
                    <div style="height: 50px;color: #333333;">流程开始</div>
                  </template>
                </el-step>

              </el-steps>
            </div>
        </div>
      </div>
    </el-drawer>
    <!-- 审核完成弹窗 -->
    <el-dialog
      title="审核结果"
      :visible.sync="dialogVisible"
      width="50%"
     >
     <div class="jg">
          <div class="jg-o">
            <div class="jg1">审核结果：</div>
            <div :class="ondelist.result==1?'jg2':'jg21' " >{{ondelist.result==1?'通过':"不通过"}}</div>
          </div>
          <div class="jg-li" style="margin-left: 100px;margin-top: 20px;">
            <div class="shjl" style="color:  #333333;font-size: 20px;" >
              <div>
                审核结果:
                <span :class="ondelist.result==1?'':'spnacitive'">【{{ ondelist.result==1?"通过":"不通过" }}】</span>
              </div>
              <div class="shmag" v-for="(x,c) in ondelist.commentList">
                <div v-if="x.comment==''&&ondelist.result==1">审核意见：审核通过</div>
                <div v-else>审核意见：{{ x.comment }}</div>

                <img style="width: 100px;height: 100px;" v-show="d.commentImgUrl" v-for="(d,f) in x.commentImgList" :src="d.commentImgUrl?d.commentImgUrl:'' " alt="">
              </div>
              <div>
            </div>
          </div>
        </div>
        </div>

     <div style="text-align: center;margin-top: 30px">
        <el-button style="width: 105px;height: 43px;" @click="dialogVisible = false">取 消</el-button>
        <el-button style="width: 105px;height: 43px;" type="primary" @click="submit()">确 定</el-button>
     </div>


    </el-dialog>
    <!-- 选择图片弹窗 -->
    <el-dialog title="选择图片" :visible.sync="dialogVisibleimg" width="50%">
      <div style="height: 500px;overflow: auto;font-size: 14rpx;">
        <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>支架照片</div>
        </div>
        <div class="jgdrg" style="display: flex;flex-wrap: wrap;">
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">整体主视图、左视图照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.holderMainImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(1,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">支架基座、水泥墩基座照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.holderBaseImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(2,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">拉杆及斜背拉照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.holderBarImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(3,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">东、西边压块照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.holderEastwestImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(4,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">基础防水照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.holderWaterproofImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(5,i)"
              />
            </div>
          </div>
        </div>
        <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>组件照片</div>
        </div>
        <div class="jgdrg" style="display: flex;flex-wrap: wrap;">
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">整体照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.moduleMainImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(6,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">东南西三个方向照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.moduleThreedirectionImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(7,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">组件间、组件与支架接地照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.moduleHolderBaseImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(8,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">组件阵列倾角照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.moduleDipAngleImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(9,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">组件近景照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.moduleCloseShotImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(10,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">压块、背压板照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.moduleBlockImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(11,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">组件铭牌照</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.moduleNameplateImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(12,i)"
              />
            </div>
          </div>
        </div>
        <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>逆变器照片</div>
        </div>
        <div class="jgdrg" style="display: flex;flex-wrap: wrap;">
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">逆变器整体照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.inverterMainImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(13,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">直流侧输入端子接线照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.inverterDirectCurrentImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(14,i)"
              />
            </div>
          </div>
        </div>
        <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>配电箱照片</div>
        </div>
        <div class="jgdrg" style="display: flex;flex-wrap: wrap;">
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">配电箱整体照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.boxMainImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(15,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">配电箱内元器件及电缆进出孔封堵照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.boxInnerImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(16,i)"
              />
            </div>
          </div>
        </div>
        <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>电缆敷设照片</div>
        </div>
        <div class="jgdrg" style="display: flex;flex-wrap: wrap;">
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">电缆敷设整体及穿管固定照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.cableMainImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(17,i)"
              />
            </div>
          </div>
        </div>
        <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>接地照片</div>
        </div>
        <div class="jgdrg" style="display: flex;flex-wrap: wrap;">
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">接地整体照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.groundingMainImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(18,i)"
              />
            </div>
          </div>
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">支架接地、配电箱电气接地电阻测量照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.groundingHolderImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(19,i)"
              />
            </div>
          </div>
        </div>
        <div class="title">
                <img src="../../assets/images/normal_u10.svg" alt="">
                <div>接地照片</div>
        </div>
        <div class="jgdrg" style="display: flex;flex-wrap: wrap;">
          <div class="imglist">
            <div style="font-size: 14px;margin-bottom: 20px">逆变器、采集器铭牌照片</div>
            <div class="list">
              <img
                v-for="(item, i) in userdata.nameplateImgList"
                :key="i"
                :src="item.constructionImgUrl"
                alt=""
                :class="item.flag == 1 ? 'listactive' : ''"
                @click="xzone(20,i)"
              />
            </div>
          </div>
        </div>
      </div>
        <div style="text-align: center;margin-top: 30px">
          <el-button
            style="width: 105px;height: 43px;"
            @click="dialogVisibleimg = false"
            >取 消</el-button
          >
          <el-button
            style="width: 105px;height: 43px;"
            type="primary"
            @click="pushimg"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
</template>

  <script>
  export default {
    methods: {
      close(i) {
      console.log(this.ondelist, "lll");
      this.ondelist.commentList.splice(i, 1);
      },
      colseimg(i,d){
        this.ondelist.commentList[i].commentImgList.splice(d, 1);
      },
      handleSizeChange(val){
        this.query.pageSize = val
        this.getdata()
      },
      handleCurrentChange(val){
         this.query.pageSize = val
        this.getdata()
      },
      submit(){
        const userinfo = JSON.parse(localStorage.getItem('userInfo'))
        this.ondelist.customerCode = this.coid
        this.ondelist.constructionCode = this.userdata.constructionCode
        this.ondelist.createCode =userinfo.userInfo.userCode
        this.ondelist.createName =userinfo.userInfo.name
        console.log(this.ondelist);
        this.$http.post("houseInstallExamineNode/addConstructionExamine", this.ondelist).then(res => {
          console.log(res);
          this.getdata()
          this.dialogVisible = false
          this.drawer = false
        });
      },
      sh(id) {
        console.log(id,'id');
        this.coid = id
        this.drawer = true;
        this.$http.post("constructionCustomer/queryConstructionImg",{
          customerCode:id
        }).then(res=>{
          if(res.data.code === 200){
            console.log(res.data.data,1091);
          this.userdata = res.data.data
          this.userdetail = res.data.data.houseCustomer
          this.$http.post("houseInstallExamineNode/queryConstructionExamineDetail", {
            customerCode:res.data.data.customerCode
          }).then(res=>{
            this.rightdata = res.data.data;
            // 使步骤条倒序
            this.$nextTick(()=>{
              var x = document.querySelectorAll(".teps .el-step__icon-inner")
              x.forEach((item,index)=>{
                item.innerHTML = x.length-index
              })
            })

          })
          }else {
            this.$message.error(res.data.message)
          }

        })
      },
      pushimg() {
        this.dialogVisibleimg = false;
        const flagged1 = this.userdata.holderMainImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged2 = this.userdata.holderBaseImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged3 = this.userdata.holderBarImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged4 = this.userdata.holderEastwestImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged5 = this.userdata.holderWaterproofImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged6 = this.userdata.moduleMainImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged7 = this.userdata.moduleThreedirectionImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged8 = this.userdata.moduleHolderBaseImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged9 = this.userdata.moduleDipAngleImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged10 = this.userdata.moduleCloseShotImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged11 = this.userdata.moduleBlockImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged12 = this.userdata.moduleNameplateImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged13 = this.userdata.inverterMainImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged14 = this.userdata.inverterDirectCurrentImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged15 = this.userdata.boxMainImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged16 = this.userdata.boxInnerImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged17 = this.userdata.cableMainImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged18 = this.userdata.groundingMainImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged19 = this.userdata.groundingHolderImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        const flagged20 = this.userdata.nameplateImgList.filter(item => item.flag === 1).map((item, i) => {
          return {
            commentImgNo: i,
            commentImgUrl: item.constructionImgUrl
          }
        });
        this.imglll = [... flagged1, ... flagged2, ... flagged3, ... flagged4, ... flagged5, ... flagged6,... flagged7,... flagged8,... flagged9,... flagged10,
        ... flagged12,... flagged13,... flagged14,... flagged15,... flagged16,... flagged17,... flagged18,... flagged19,... flagged20];
        console.log(this.imglll);
        this.ondelist.commentList[this.d].commentImgList=this.imglll;





      },
      xzone(index,i) {
        if(index==1){
          if(this.userdata.holderMainImgList[i].flag == 1){
            this.userdata.holderMainImgList[i].flag = 0
          } else {
            this.userdata.holderMainImgList[i].flag = 1
          }
        }
        if(index==2){
          if(this.userdata.holderBaseImgList[i].flag == 1){
            this.userdata.holderBaseImgList[i].flag = 0
          } else {
            this.userdata.holderBaseImgList[i].flag = 1
          }
        }
        if(index==3){
          if(this.userdata.holderBarImgList[i].flag == 1){
            this.userdata.holderBarImgList[i].flag = 0
          } else {
            this.userdata.holderBarImgList[i].flag = 1
          }
        }
        if(index==4){
          if(this.userdata.holderEastwestImgList[i].flag == 1){
            this.userdata.holderEastwestImgList[i].flag = 0
          } else {
            this.userdata.holderEastwestImgList[i].flag = 1
          }
        }
        if(index==5){
          if(this.userdata.holderWaterproofImgList[i].flag == 1){
            this.userdata.holderWaterproofImgList[i].flag = 0
          } else {
            this.userdata.holderWaterproofImgList[i].flag = 1
          }
        }
        if(index==6){
          if(this.userdata.moduleMainImgList[i].flag == 1){
            this.userdata.moduleMainImgList[i].flag = 0
          } else {
            this.userdata.moduleMainImgList[i].flag = 1
          }
        }
        if(index==7){
          if(this.userdata.moduleThreedirectionImgList[i].flag == 1){
            this.userdata.moduleThreedirectionImgList[i].flag = 0
          } else {
            this.userdata.moduleThreedirectionImgList[i].flag = 1
          }
        }
        if(index==8){
          if(this.userdata.moduleHolderBaseImgList[i].flag == 1){
            this.userdata.moduleHolderBaseImgList[i].flag = 0
          } else {
            this.userdata.moduleHolderBaseImgList[i].flag = 1
          }
        }
        if(index==9){
          if(this.userdata.moduleDipAngleImgList[i].flag == 1){
            this.userdata.moduleDipAngleImgList[i].flag = 0
          } else {
            this.userdata.moduleDipAngleImgList[i].flag = 1
          }
        }
        if(index==10){
          if(this.userdata.moduleCloseShotImgList[i].flag == 1){
            this.userdata.moduleCloseShotImgList[i].flag = 0
          } else {
            this.userdata.moduleCloseShotImgList[i].flag = 1
          }
        }
        if(index==11){
          if(this.userdata.moduleBlockImgList[i].flag == 1){
            this.userdata.moduleBlockImgList[i].flag = 0
          } else {
            this.userdata.moduleBlockImgList[i].flag = 1
          }
        }
        if(index==12){
          if(this.userdata.moduleNameplateImgList[i].flag == 1){
            this.userdata.moduleNameplateImgList[i].flag = 0
          } else {
            this.userdata.moduleNameplateImgList[i].flag = 1
          }
        }
        if(index==13){
          if(this.userdata.inverterMainImgList[i].flag == 1){
            this.userdata.inverterMainImgList[i].flag = 0
          } else {
            this.userdata.inverterMainImgList[i].flag = 1
          }
        }
        if(index==14){
          if(this.userdata.inverterDirectCurrentImgList[i].flag == 1){
            this.userdata.inverterDirectCurrentImgList[i].flag = 0
          } else {
            this.userdata.inverterDirectCurrentImgList[i].flag = 1
          }
        }
        if(index==15){
          if(this.userdata.boxMainImgList[i].flag == 1){
            this.userdata.boxMainImgList[i].flag = 0
          } else {
            this.userdata.boxMainImgList[i].flag = 1
          }
        }
        if(index==16){
          if(this.userdata.boxInnerImgList[i].flag == 1){
            this.userdata.boxInnerImgList[i].flag = 0
          } else {
            this.userdata.boxInnerImgList[i].flag = 1
          }
        }
        if(index==17){
          if(this.userdata.cableMainImgList[i].flag == 1){
            this.userdata.cableMainImgList[i].flag = 0
          } else {
            this.userdata.cableMainImgList[i].flag = 1
          }
        }
        if(index==18){
          if(this.userdata.groundingMainImgList[i].flag == 1){
            this.userdata.groundingMainImgList[i].flag = 0
          } else {
            this.userdata.groundingMainImgList[i].flag = 1
          }
        }
        if(index==19){
          if(this.userdata.groundingHolderImgList[i].flag == 1){
            this.userdata.groundingHolderImgList[i].flag = 0
          } else {
            this.userdata.groundingHolderImgList[i].flag = 1
          }
        }
        if(index==20){
          if(this.userdata.nameplateImgList[i].flag == 1){
            this.userdata.nameplateImgList[i].flag = 0
          } else {
            this.userdata.nameplateImgList[i].flag = 1
          }
        }
      },
      getdata() {
        this.$http.post("constructionCustomer/constructExamininglist", this.queryInfo).then(res => {
          this.tableData = res.data.data.data;
          this.count = res.data.data.count
        });
      },
      query() {
        this.queryInfo.condition = this.formInline
        this.$http.post("constructionCustomer/constructExamininglist", this.queryInfo).then(res => {
          this.tableData = res.data.data.data;
          this.count = res.data.data.count
        });
      },
      reset() {
        this.formInline = {
          constructionCode:"",
          customerName:"",
          customerPhone:""
        }
      },
      pushone(){
        this.ondelist.commentList.push({
                  comment:"",
                  commentNo:"",
                  commentImgList:[
                    {
                      commentImgNo:"",
                      commentImgUrl:"",
                    }
                  ]
                })

      },
      openimg(d,i){
        this.$http.post("constructionCustomer/queryConstructionImg",{
          customerCode:this.coid
        }).then(res=>{
          this.userdata = res.data.data
        })
        this.d = d
        console.log(d,i);
        this.diashow = i;
        this.dialogVisibleimg = true;


      }

    },
    mounted(){
      this.timer = setInterval(()=>{
        this.getdata()
      },300000)
    },
    beforeDestroy(){
      clearInterval(this.timer);
    },
    created(){
      this.getdata()
    },
    data() {
      return {
        timer:'',
        userdetail:{},
        dialogVisibleimg:false,
        userdata:{},
        coid:'',
        rightdata:{},
        roofNum:[],
        ondelist:{
              constructionCode:'',//施工编码
              createCode:'',//审批创建人code
              createName:'',//审批创建人name
              customerCode:'',//客户编码
              result:0,//审核结果
              commentList:[
                {
                  comment:"",
                  commentNo:"",
                  commentImgList:[
                    {
                      commentImgNo:"",
                      commentImgUrl:"",
                    }
                  ]
                }
              ]
            },
        count:0,
        queryInfo: {
        pageSize: 20,
        pageCount: 1,
        currPage: 1,
        condition: {
            // dataStatus:3,
            // mobile: "",
            // explorateCode:'',
            // customerName:'',
          }
        },
        activities: [{
         time:"1998-10-09",
         one:"通过",
         two:"通过",
         three:"通过",
         four:"不通过",
         content:{
          title:"",
          tex:{
            name:"",
            img:[]
          },
          texs:{
            name:"",
            img:[]
          }
         }
        },{
         time:"1998-10-09",
         one:"通过",
         two:"通过",
         three:"通过",
         four:"不通过",
         content:{
          title:"审批意见1",
          tex:{
            name:"1.资料补全",
            img:[]
          },
          texs:{
            name:"2.资料补全",
            img:["https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"]
          }
         }
        }],
        drawer:false,
        formInline:{
          constructionCode:"",
          customerName:"",
          customerPhone:""
        },
        dialogVisible:false,
        tableData: [
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
      ],
      }
    },
  };
  </script>

  <style lang="less" scoped>
  .accep {
    padding-left: 5px;
    .user {
        background-color: #fff;
    }
    .biao {
        overflow: auto;
        background-color: #fff;
        margin-top: 20px;
        padding-top: 10px;
    }
    .box-dia {
        .bo-tit {
            color: #333333;
            font-size: 18px;
            border-top: 1px solid #d7d7d7;
           padding: 30px;
        }
        .wj {
            text-align: center;
            width:150px ;
            margin-left: 30px;
            img {
                width: 150px;
                height: 150px;
                padding: 2px 2px 2px 2px;
                border-radius: 5px;
                border: 1px solid #d7d7d7;
            }
            div {
                color: #aaaaaa;
                font-size: 15px;
                margin-top: 10px;
            }
        }
    }
  }
  .drw {
  display: flex;
  height: 100vh;
}
.drw-left {
    padding-top: 15px;
    width: 70%;
    border-right: 1px solid #c1c1c1;
  .drw-user {
   .jj {
    display: flex;
    div {
      flex: 1;
      margin: 10px;
    }
   }
  }
  .anxx {
    div {
        color: #2e64ee;
        font-size: 16px;
        height: 30px;
        width: 70px;
        border-bottom: 3px solid #2e64ee;
        margin-left: 10px;
    }
    border-bottom: 10px solid #f2f2f2;
  }
  .box {

      height: 60vh;
      overflow: auto;
    .zjli {
        display: flex;
        margin-left: 60px;
        flex-wrap: wrap;

        .zj-img {
            width: 50%;
            // margin-top: 20px;
            .img-li {
                display: flex;
                flex-wrap: wrap;
                div {
                    color: #555555;
                    font-size: 16px;

                }
                img {
                    margin-top: 15px;
                    width: 100px;
                    height: 100px;
                    border-radius: 5px;
                    margin-right: 10px;
                }
            }

        }
    }
    .de-bom {
        padding-left: 100PX;
        border-top: 6px solid #e2e2e2;
        .bom-1 {
          display: flex;
          margin: 15px;
        }
        .tips {
          // width: 744px;
          // height: 252px;
          position: relative;
          padding: 2px 2px 2px 2px;
          border-radius: 5px;
          border: 1px solid rgba(215, 215, 215, 0.45);
          margin-left: 15px;
          padding: 20px;
          .xz {
            margin: 15px;
          }
          .yj {
            margin: 15px;
          }
          .imgliscoo {
            position: relative;
            .colseimg {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
          }
          }

          .colse {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
        }
      }
  }
}
.drw-right {
  width: 30%;
  padding-top: 15px;
  padding-left: 20px;
  .bo {
    margin-left: 60%;
    width: 100px;
    height: 40px;
  }
  .right-tit {
    border-bottom: 1px solid rgba(215, 215, 215, 0.55);
    line-height: 40px;

  }
}
.jg {
  padding-left: 40px;
  .jg-o {
    display: flex;
    align-items: center;

    .jg1 {
      color: #333333;
      font-size: 20px;

    }
    .jg2 {
      width: 105px;
      height: 33px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      border: 1px solid #02a7f0;
      background-color: #ffffff;
      color: #02a7f0;
      text-align: center;
      line-height: 33px;
    }
    .jg21 {
      width: 105px;
      height: 33px;
      padding: 2px 2px 2px 2px;
      border-radius: 5px;
      border: 1px solid #ea2e15;
      background-color: #ffffff;
      color: #ea2e15;
      text-align: center;
      line-height: 33px;
    }
  }
  .jg-li {
    .shjl {
      div {
        margin-bottom: 15px;
      }
      span {
        color: #63a103;
      }
      .spnacitive {
        color: red;
      }
    }
  }
}
.jgdrg {
    .imglist {
      // margin-left: 40px;
      width: 40%;
      margin-left: 30px;
      .list {
        display: flex;
        img {
          width: 91px;
          height: 91px;
          border-radius: 5px;
          margin-right: 20px;
        }
        .listactive {
          border: 3px solid skyblue;
        }
      }
    }
  }
  .listactive {
          border: 3px solid skyblue;
        }
.title {
            display: flex;
            font-size: 18px;
            color: #333;
            font-weight: 700;

            align-items: center;
            // height: 50px;
            padding-left: 20px;
            img {
                margin-right: 10px;
                width: 8px;
                height: 30px;
            }
        }
  /deep/ .el-form-item__label {
    // width: 100px !important;
  }
  /deep/.el-input__inner {
    // width: 250px;
  }
  /deep/.el-table  {
    height: 500px;
  }

  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color:  #f59a23 !important;
}
/deep/ .el-table td {
    padding: 4px 0;

  }
  /deep/ .el-form-item__label {

    font-size: 11px;
  }
  .pagination {
    text-align: right;
    // float: right;
  }
  /deep/ .el-step__icon.is-text {
    background-color: #02a7f0;
    color: #fff;
    border: 2px solid #02a7f0 ;
  }
  /deep/ .el-drawer__header>:first-child {
  color: #000;
  font-weight: 700;
  font-size: 18px;
}
/deep/ .el-drawer__header {
    margin-bottom: 0px;
    border-bottom: 1px solid #c1c1c1;
    padding: 20px;
}
.spnacitive {
  color: red;
}
.spnacitiveon {
  color: #63a103;
}
.box-btn {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
  </style>
